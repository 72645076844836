<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.descricao"
        class="col-12 col-md-10"
        :label="$t('modulos.categoria_item.formulario.descricao')"
        obrigatorio
      />
      <input-select
        v-model="form.flagPeca"
        class="col-12 col-md-2"
        :label="$t('modulos.tipo_item.formulario.peca')"
        :options="opcoes.Booleano"
      />
      <input-textarea
        v-model="form.descricaoCompleta"
        class="col-12"
        :label="$t('modulos.categoria_item.formulario.descricaoCompleta')"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import CategoriaItemService from '@common/services/cadastros/CategoriaItemService.js';
import { CategoriaItemModel } from '@common/models/cadastros/CategoriaItemModel.js';
import helpers from '@common/utils/helpers';

export default {
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new CategoriaItemModel({}),
      opcoes: {
        Booleano: helpers.BoleanoEnum,
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.categoria_item.titulos.editar');
      return this.$t('modulos.categoria_item.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.categoria_item.validacoes.formulario_invalido');
    },
  },
  mounted() {
    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this,'CategoriaItem', 'Editar');
    }
    helpers.redirecionarSemPermissao(this, 'CategoriaItem', 'Inserir');

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      CategoriaItemService.buscar(this.id)
        .then((res) => {
          this.form = new CategoriaItemModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.categoria_item.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
        if(!this.valido) return
      this.$store.dispatch('Layout/iniciarCarregamento');
      CategoriaItemService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.categoria_item.cadastro_sucesso`));
          this.$router.push({ name: 'categoria-item' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'categoria-item' });
      });
    },
  },
};
</script>
